import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Brand } from "app/core/models/brand.model";
import { Company } from "app/core/models/company.model";
import { Hotel } from "app/core/models/hotel.model";
import { InstanceActiveService } from "app/core/services/instance-active.service";
import { UserDetailsService } from "app/core/services/user-details.service";
import { mergeMap, of, take } from "rxjs";

@UntilDestroy()
@Component({
    selector: "instance-selector",
    templateUrl: "./instance-selector.component.html",
    styleUrls: ["./instance-selector.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
    /*
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush*/
})
export class InstanceSelectorComponent implements OnInit {
    companies: Array<Company> = [];
    hotels: Array<Hotel> = [];
    hotelsFiltered: Array<Hotel> = [];
    brands: Array<Brand> = [];

    selectedCompany: Company;
    selectedHotel: Hotel;
    selectedBrand: Brand;

    companiesDropdownDisabled = false;
    brandsDropdownDisabled = false;
    hotelsDropdownDisabled = false;

    /**
     * Constructor
     */
    constructor(
        private _userDetailsService: UserDetailsService,
        private _instanceActiveService: InstanceActiveService,
        private changeDetectorRef: ChangeDetectorRef
    ) { }



    ngOnInit(): void {

        

        
        /*this.brandsDropdownDisabled = !(
            this._userDetailsService.hasAnyPermission("ROLE_ADC", "ROLE_ADB", "ROLE_NOC") || this.brands.length > 1
        );
        this.hotelsDropdownDisabled = !(
            (
                this._userDetailsService.hasAnyPermission("ROLE_ADC", "ROLE_ADB", "ROLE_NOC", "ROLE_RST", "ROLE_HSP") ||
                this.hotels.length > 1
            )
        );*/


        const instanceSelector = this._instanceActiveService.currentInstanceSelector;

        if (instanceSelector) {

            console.log("Ya existe un instance selector previo", instanceSelector)

            if (instanceSelector.company) {
                this.selectedCompany = instanceSelector.company;
            }

            if (instanceSelector.brand) {
                this.selectedBrand = instanceSelector.brand;
            }

            if (instanceSelector.hotel) {
                this.selectedHotel = instanceSelector.hotel;
            }

            console.log("Selected Company", this.selectedCompany);
            console.log("Selected Brand", this.selectedBrand);
            console.log("Selected Hotel", this.selectedHotel)
        }


        this._instanceActiveService
            .findCompanies()
            .pipe(
                untilDestroyed(this),
                take(1),
                mergeMap((companies: Company[]) => {
                    this.companies = companies.sort((a, b) => a.name.localeCompare(b.name));


                    if (this.selectedCompany) {

                        return this._instanceActiveService
                            .findBrandsByIdCompany(this.selectedCompany.id)
                            .pipe(untilDestroyed(this), take(1))
                    }

                    return of([]);

                }),
                mergeMap((brands: Brand[]) => {
                    this.brands = brands.sort((a, b) => a.name.localeCompare(b.name));

                    if (this.selectedBrand) {
                        return this._instanceActiveService
                            .findHotelsByIdBrand(this.selectedBrand.id)
                            .pipe(untilDestroyed(this), take(1))
                    }

                    return of([])
                })


            ).subscribe((hotels: Hotel[]) => {
                this.hotels = hotels.sort((a, b) => a.name.localeCompare(b.name));

                this.companiesDropdownDisabled = !this._userDetailsService.hasAnyPermission("ROLE_NOC") || this.companies.length === 1;

                console.log("Length companies", this.companies.length)

                if (this.companies.length === 1 && !this.selectedCompany) {
                    console.log("Setting selectedCompany to", this.companies[0]);
                    this.selectedCompany = this.companies[0];
                }

                this.changeDetectorRef.markForCheck();
               
                /*if (this.companies.length === 1) {
                    this.selectedCompany = this.companies[0];
                }

                if (this.brands.length === 1) {
                    this.brandsDropdownDisabled = true;
                    this.selectedBrand = this.brands[0];
                }

                if (this.hotels.length === 1) {
                    this.hotelsDropdownDisabled = true;
                    this.selectedHotel = this.hotels[0];
                }*/
            });






    }

    onCompanyChange(saveContext: boolean = true): void {

        this.selectedBrand = null;
        this.selectedHotel = null;

        if (saveContext) {
            console.log("On company Change", this.selectedCompany);
            this._instanceActiveService.currentCompany = this.selectedCompany;
        }

        this._instanceActiveService
            .findBrandsByIdCompany(this.selectedCompany.id)
            .pipe(untilDestroyed(this), take(1))
            .subscribe(data => {
                this.brands.length = 0;
                this.brands.push(...data.sort((a, b) => a.name.localeCompare(b.name)));

                /*if (this.brands.length === 1) {
                    this.brandsDropdownDisabled = true;
                    this.selectedBrand = this.brands[0];
                } else {
                    this.brandsDropdownDisabled = false;
                }*/

                    this.changeDetectorRef.markForCheck();


            });


    }

    onBrandChange(saveContext: boolean = true): void {

        this.selectedHotel = null;

        if (this.selectedBrand) {
            this._instanceActiveService
                .findHotelsByIdBrand(this.selectedBrand.id)
                .pipe(untilDestroyed(this), take(1))
                .subscribe(data => {
                    this.hotels.length = 0;
                    this.hotels.push(...data.sort((a, b) => a.name.localeCompare(b.name)));
                    this.hotelsFiltered.length = 0;
                    this.hotelsFiltered.push(...data.sort((a, b) => a.name.localeCompare(b.name)));

                    /*if (this.hotels.length === 1) {
                        this.hotelsDropdownDisabled = true;
                        this.selectedHotel = this.hotels[0];
                    } else {
                        this.hotelsDropdownDisabled = false;
                    }*/

                        this.changeDetectorRef.markForCheck();

                });
        }

        if (saveContext) {
            this._instanceActiveService.currentBrand = this.selectedBrand;
        }
    }

    onHotelChange(saveContext: boolean = true): void {

        if (saveContext) {
            this._instanceActiveService.currentHotel = this.selectedHotel;

            this.changeDetectorRef.markForCheck();
        }
    }

    selectCompany(company: Company, saveContext: boolean = true) {
        console.log("Select Company:", company);
        this.selectedCompany = company;
        this.onCompanyChange(saveContext);
    }

    selectBrand(brand: Brand, saveContext: boolean = true) {
        this.selectedBrand = brand;
        this.onBrandChange(saveContext);

    }

    selectHotel(hotel: Hotel, saveContext: boolean = true) {
        this.selectedHotel = hotel;
        this.onHotelChange(saveContext);
    }

    compareEntities(e1: any, e2: any): boolean {
        if ((!e1 && e2) || (e1 && !e2)) {
            return false;
        }

        return e1.id === e2.id;
    }


}
